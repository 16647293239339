<template>
  <div>
    <LoadingPage v-show="loaded" />
    <div v-show="!loaded">
      <section>
        <NbPageTitle :title="$t('volumesReversePage.title')" />
        <div class="d-flex">
          <Alert
            class="mx-2 mb-3"
            height="85"
            width="270"
            size="lg"
            type="danger"
            icon="alert-circle"
            :message="ticketErrorMessage"
          />
          <Alert
            class="mx-2"
            height="85"
            width="270"
            size="lg"
            type="warning"
            icon="alert-circle"
            :message="ticketWaitingMessage"
          />
        </div>
        <NbTabs
          class="mb-3"
          :tabs="tabsOptions"
          @switchTab="switchTab($event)"
        />
        <NbTablev2
          tableOf="volumesReversePage"
          namespace="volume_reverses"
          ref="volumesReversePage"
          :allFields="allFields"
          :fields="fields"
          :filterOptions="filterOptions"
          @optionTook="optionTook($event)"
          :selectedItems.sync="selectedOrders"
          @reloadFields="fields = $event"
          @total="totalVolumesReverse = $event"
        >
          <!-- table options - but it's not working
            :showBtnOptions="true"
            :buttonOptions="volumeReverseBtnOptions"
            :selectable="true"
          -->
          <template #cell(id)="data">
            <!-- {{data.item.volume.order.order_number}} -->
            <div
              class="link-1"
              data-toggle="modal"
              data-target="#modal-reverse-view"
              @click="getVolumeReverseModal(data.item)"
            >
              {{ checkValue(data.item.id) }}
            </div>
          </template>

          <template #cell(order_number)="data">
            <div
              class="link-1"
              @click="getOrderModal(data.item.volume.order.id)"
            >
              {{ checkValue(data.item.volume.order.order_number) }}
            </div>
          </template>

          <template #cell(order_id)="data">
            {{ data.item.volume.order.id }}
          </template>

          <template #cell(customer_full_name)="data">
            {{ data.item.volume.order.customer_full_name }}
          </template>

          <template #cell(order_items_name)="data">
            <span v-for="item in data.item.volume.order_items" :key="item.id">
              {{ item.name }}
            </span>
          </template>

          <template #cell(order_items_description)="data">
            <span v-for="item in data.item.volume.order_items" :key="item.id">
              {{ item.description }}
            </span>
          </template>

          <template #cell(status)="data">
            <NbBadge
              v-if="data.item.status"
              class="fit-content"
              :type="getBadgeType(data.item.status)"
              :text="titleString(data.item.status)"
            />
            <div v-else>-</div>
          </template>

          <template #cell(order_items_hs_code)="data">
            <span v-for="item in data.item.volume.order_items" :key="item.id">
              {{ item.hs_code }}
            </span>
          </template>

          <template #cell(created_at)="data">
            {{
              data.item.created_at ? data.item.created_at.substring(0, 10) : "-"
            }}
          </template>
        </NbTablev2>
      </section>

      <NbFooter
        :text="$t('components.footer.checkOurSupport')"
        link="https://nobordistinc.freshdesk.com/support/home"
      />

      <!-- modals -->
      <ModalVolumeReverseView :currentVolumeReverse="currentVolumeReverse" />
      <ModalOrderView
        :orderId="currentOrderViewId"
        @reloadOrders="reloadOrders"
      />
    </div>
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import ModalVolumeReverseView from "./ModalVolumeReverseView.vue";
import ModalOrderView from "@/views/orders/components/ModalOrderView.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import Alert from "@/components/alerts/Alert.vue";

import moment from "moment";
import LoadingPage from "@/components/loadings/LoadingPage.vue";

import VolumeReverseService from "@/services/VolumeReverseService";

const volumeReverseService = new VolumeReverseService();

export default {
  name: "OrdersReverse",
  components: {
    LoadingPage,
    NbPageTitle,
    NbTabs,
    NbTablev2,
    NbFooter,
    ModalVolumeReverseView,
    ModalOrderView,
    NbBadge,
    Alert,
  },
  data() {
    return {
      loaded: true,
      ticketsWaiting: 0,
      ticketsError: 0,
      currentVolumeReverse: {},
      currentOrderViewId: "",
      currentTable: "all",
      totalVolumesReverse: 0,
      fields: [],
      filterOptions: [
        { key: "volume_id", label: "Volume ID", type: "number" },
        { key: "reverse_number", label: "Reverse Number" },
        { key: "address", label: "Address" },
        { key: "address_number", label: "Address Number" },
        { key: "address_complement", label: "Address Complement" },
        { key: "state", label: "State" },
        { key: "city", label: "City" },
        { key: "country", label: "Country" },
        {
          key: "status",
          label: "Status",
          type: "select",
          options: [
            {
              value: "ticket_error",
              text: "Error Generating Ticket",
            },
            { value: "waiting_eticket", text: "Waiting Eticket" },
            { value: "eticket_created", text: "Eticket Created" },
          ],
        },
        { key: "postal_code", label: "Postal Code" },
        { key: "order_number", label: "Order Number" },
        { key: "customer_full_name", label: "Customer Name" },
        {
          key: "registration_date",
          label: this.$t("betweenDates"),
          type: "dates",
        },
      ],
      selectedOrders: [],
    };
  },
  created() {
    setTimeout(() => {
      this.loaded = false;
    }, 1000);
    this.getTicketsInformation();
  },
  filters: {
    frontEndDateFormat: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
  },
  methods: {
    getOrderModal(id) {
      if (id) {
        this.currentOrderViewId = id;
        this.$helpers.openModal("modal-order-view");
        return;
      }
    },
    reloadOrders() {
      this.$refs?.tableOrders?.getData();
    },
    getVolumeReverseModal(item) {
      this.currentVolumeReverse = item;
    },
    optionTook(event) {
      this[event]();
    },
    getBadgeType(status) {
      if (status) {
        if (status.toLowerCase().includes("waiting")) {
          return "warning";
        }
        if (status.toLowerCase().includes("erro")) {
          return "danger";
        }
      }
      return "success";
    },

    titleString(string) {
      if (string) {
        let finalResult = string.replace(/_/g, " ");
        finalResult = finalResult
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");

        return finalResult;
      }
      return "-";
    },
    getTicketsInformation() {
      const ticketErrorsFilter = {
        limit: 1,
        offset: 0,
        status: "ticket_error",
      };
      volumeReverseService
        .getVolumeReserves(ticketErrorsFilter)
        .then((response) => {
          this.ticketsError = response.data.data.count;
        });

      const ticketWaitingFilter = {
        limit: 1,
        offset: 0,
        status: "waiting_eticket",
      };
      volumeReverseService
        .getVolumeReserves(ticketWaitingFilter)
        .then((response) => {
          this.ticketsWaiting = response.data.data.count;
        });
    },

    switchTab(tab) {
      if (this.currentTable !== tab && tab !== "all") {
        this.currentTable = tab;
        const filter = { status: tab, offset: 0 };
        this.$refs.volumesReversePage.submitFilterForm(filter);
        return;
      }
      if (this.currentTable !== tab) {
        this.currentTable = tab;
        this.$refs.volumesReversePage.submitFilterForm();
      }
    },
    selectedItems(event) {
      this.selectedOrders = event;
    },
    checkValue(/* order,*/ value) {
      if (value != null) {
        return value;
      } else {
        return "-";
      }
    },
  },
  computed: {
    volumeReverseBtnOptions() {
      return [
        {
          text: this.$t("download"),
          value: "methodHere",
        },
      ];
    },
    tabsOptions() {
      return [
        {
          id: "all",
          label: this.$t("totalItems"),
          found: this.totalVolumesReverse,
          current: this.currentTable,
        },
        /* {
          id: "new_issue",
          label: this.$t("volumesReversePage.status.new"),
          current: this.currentTable,
        }, */
      ];
    },
    allFields() {
      return [
        {
          key: "id",
          label: this.$t("volumesReversePage.fields.id"),
          sortable: true,
        },
        { key: "order_number", label: this.$t("orderNumber"), sortable: true },
        {
          key: "volume_id",
          label: this.$t("volumesReversePage.fields.volumeId"),
        },
        { key: "name", label: this.$t("name"), sortable: true },
        { key: "created_at", label: this.$t("creationDate"), sortable: true },
        {
          key: "reverse_number",
          label: this.$t("volumesReversePage.fields.reverseNumber"),
        },
        { key: "address", label: this.$t("address"), sortable: true },
        {
          key: "address_number",
          label: this.$t("addressNumber"),
          sortable: true,
        },
        {
          key: "address_complement",
          label: this.$t("volumesReversePage.fields.addressComplement"),
        },
        { key: "state", label: this.$t("state"), sortable: true },
        { key: "postal_code", label: this.$t("postalCode"), sortable: true },
        { key: "country", label: this.$t("country"), sortable: true },
        { key: "city", label: this.$t("city"), sortable: true },
        {
          key: "status",
          label: this.$t("status"),
        },
        {
          key: "customer_full_name",
          label: this.$t("customerName"),
          sortable: true,
        },
        {
          key: "order_items_name",
          label: this.$t("volumesReversePage.fields.orderItemsName"),
        },
        {
          key: "order_items_description",
          label: this.$t("volumesReversePage.fields.orderItemsDescription"),
        },
        {
          key: "order_items_hs_code",
          label: this.$t("volumesReversePage.fields.orderItemsHsCode"),
        },
      ];
    },
    ticketErrorMessage() {
      return `${this.ticketsError} ${this.$t(
        "volumesReversePage.orderErroTicket"
      )}`;
    },
    ticketWaitingMessage() {
      return `${this.ticketsWaiting} ${this.$t(
        "volumesReversePage.orderWaitingTicket"
      )}`;
    },
  },
};
</script>
