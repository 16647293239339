<template>
  <NbModal
    id="modal-reverse-view"
    modalConfig="modal-dialog-centered"
    width="72rem"
  >
    <template v-slot:header>
      <div v-if="Object.keys(currentVolumeReverse).length > 1">
        <NbPageTitle
          :title="`
            ${$t('volumesReversePage.viewTitle')}: #${currentVolumeReverse.id}
          `"
        >
        </NbPageTitle>
      </div>
    </template>

    <div v-if="Object.keys(currentVolumeReverse).length > 1">
      <NbCard
        id="reverse-card"
        :title="$t('volumesReversePage.reverseVolume')"
        class="bg-gray-05 mb-4"
      >
        <template #body>
          <div class="d-flex justify-content-between flex-wrap">
            <NbTextField
              id="reverse_number"
              class="w-32 mb-3 mt-2"
              :name="$t('volumesReversePage.reverseNumber')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.reverse_number"
            />
            <NbTextField
              id="name"
              class="w-66 mb-3 mt-2"
              :name="$t('name')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.name"
            />
            <NbTextField
              id="address"
              class="w-42 mb-3 mt-2"
              :name="$t('address')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.address"
            />
            <NbTextField
              id="address_number"
              class="w-12 mb-3 mt-2"
              :name="$t('addressNumber')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.address_number"
            />
            <NbTextField
              id="address_complement"
              class="w-42 mb-3 mt-2"
              :name="$t('complement')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.address_complement"
            />
            <NbTextField
              id="city"
              class="w-35 mb-3 mt-2"
              :name="$t('city')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.city"
            />
            <NbTextField
              id="state"
              class="w-12 mb-3 mt-2"
              :name="$t('state')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.state"
            />
            <NbTextField
              id="postal_code"
              class="w-35 mb-3 mt-2"
              :name="$t('postalCode')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.postal_code"
            />
            <NbTextField
              id="country"
              class="w-12 mb-3 mt-2"
              :name="$t('country')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.country"
            />
          </div>
        </template>
      </NbCard>

      <NbCard id="order-card" :title="$t('order')" class="bg-gray-05 mb-4">
        <template #body>
          <div class="d-flex justify-content-between flex-wrap">
            <NbTextField
              id="order_number"
              class="w-32 mb-3 mt-2"
              :name="$t('orderNumber')"
              variant="borderless-gray-10"
            >
              <div
                class="link-1"
                @click="getOrderModal(currentVolumeReverse.volume.order.id)"
              >
                {{ currentVolumeReverse.volume.order.order_number }}
              </div>
            </NbTextField>
            <NbTextField
              id="sales_channel_name"
              class="w-32 mb-3 mt-2"
              :name="$t('volumesReversePage.salesChannelName')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.volume.order.sales_channel_name"
            />
            <NbTextField
              id="sales_channel_order_number"
              class="w-32 mb-3 mt-2"
              :name="$t('salesChannelOrderNumber')"
              variant="borderless-gray-10"
              :value="
                currentVolumeReverse.volume.order.sales_channel_order_number
              "
            />
            <NbTextField
              id="reference"
              class="w-32 mb-3 mt-2"
              :name="$t('volumesReversePage.reference')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.volume.order.reference"
            />
            <NbTextField
              id="volume_id"
              class="w-32 mb-3 mt-2"
              :name="$t('volumesReversePage.volumeId')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.volume.id"
            />
            <NbTextField
              id="last_mile_tracking_number"
              class="w-32 mb-3 mt-2"
              :name="$t('lastMileTrackingNumber')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.volume.last_mile_tracking_number"
            />
            <NbTextField
              id="height"
              class="w-18 mb-3 mt-2"
              :name="$t('height')"
              variant="borderless-gray-10"
              :value="standardizeFloat(currentVolumeReverse.volume.height, 1)"
            />
            <NbTextField
              id="width"
              class="w-18 mb-3 mt-2"
              :name="$t('width')"
              variant="borderless-gray-10"
              :value="standardizeFloat(currentVolumeReverse.volume.width, 1)"
            />
            <NbTextField
              id="length"
              class="w-18 mb-3 mt-2"
              :name="$t('length')"
              variant="borderless-gray-10"
              :value="standardizeFloat(currentVolumeReverse.volume.length, 1)"
            />
            <NbTextField
              id="weight"
              class="w-18 mb-3 mt-2"
              :name="$t('weight')"
              variant="borderless-gray-10"
              :value="standardizeFloat(currentVolumeReverse.volume.weight, 3)"
            />
            <NbTextField
              id="total_value"
              class="w-18 mb-3 mt-2"
              :name="$t('totalValue')"
              variant="borderless-gray-10"
              :value="
                standardizeValue(
                  currentVolumeReverse.volume.order.total_value,
                  currentVolumeReverse.volume.order.currency
                )
              "
            />
          </div>
        </template>
      </NbCard>

      <NbCard
        id="customer-card"
        :title="$t('customer')"
        class="bg-gray-05 mb-4"
      >
        <template #body>
          <div class="d-flex justify-content-between flex-wrap">
            <NbTextField
              id="customer_full_name"
              class="w-32 mb-3 mt-2"
              :name="$t('name')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.volume.order.customer_full_name"
            />
            <NbTextField
              id="customer_document_number"
              class="w-32 mb-3 mt-2"
              :name="currentVolumeReverse.volume.order.customer_document_type"
              variant="borderless-gray-10"
              :value="
                currentVolumeReverse.volume.order.customer_document_number
              "
            />
            <NbTextField
              id="customer_phone"
              class="w-32 mb-3 mt-2"
              :name="$t('phone')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.volume.order.customer_phone"
            />
            <NbTextField
              id="customer_state"
              class="w-16 mb-3 mt-2"
              :name="$t('state')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.volume.order.customer_state"
            />
            <NbTextField
              id="customer_postal_code"
              class="w-32 mb-3 mt-2"
              :name="$t('postalCode')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.volume.order.customer_postal_code"
            />
            <NbTextField
              id="customer_country"
              class="w-16 mb-3 mt-2"
              :name="$t('country')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.volume.order.customer_country"
            />
            <NbTextField
              id="customer_email"
              class="w-32 mb-3 mt-2"
              :name="$t('email')"
              variant="borderless-gray-10"
              :value="currentVolumeReverse.volume.order.customer_email"
            />
            <NbTextField
              id="address"
              class="w-100 mb-3 mt-2"
              :name="$t('address')"
              variant="borderless-gray-10"
              :value="
                currentVolumeReverse.volume.order.customer_address +
                (currentVolumeReverse.volume.order.customer_address_number
                  ? ' ' +
                    currentVolumeReverse.volume.order.customer_address_number
                  : '') +
                (currentVolumeReverse.volume.order.customer_address_complement
                  ? ', ' +
                    currentVolumeReverse.volume.order
                      .customer_address_complement
                  : '') +
                (currentVolumeReverse.volume.order.customer_address_reference
                  ? ' (' +
                    currentVolumeReverse.volume.order
                      .customer_address_reference +
                    ') '
                  : '') +
                ' - ' +
                currentVolumeReverse.volume.order.customer_city
              "
            />
          </div>
        </template>
      </NbCard>

      <NbTablev2
        v-if="currentVolumeReverse.volume.order_items"
        tableOf="productsImport"
        :allFields="allFields"
        :fields="fields"
        :startPerPage="999"
        height="fit-content"
        :hasParentItens="true"
        :startItems="currentVolumeReverse.volume.order_items"
        :hiddeTableOptions="true"
        @tableLoaded="tableLoaded($event)"
        @reloadFields="fields = $event"
      >
        <template #cell(value)="data">
          {{ standardizeValue(data.item.value, data.item.currency) }}
        </template>
      </NbTablev2>
    </div>

    <template v-slot:footer>
      <div class="d-flex justify-content-end">
        <NbButton variant="secondary" data-dismiss="modal">
          {{ $t("close") }}
        </NbButton>
      </div>
    </template>
    <ModalOrderView
      id="order-view-from-volume"
      :orderId="currentOrderViewId"
      @reloadOrders="reloadOrders"
    />
  </NbModal>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbTextField from "@/components/input/text/NbTextField.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import ModalOrderView from "@/views/orders/components/ModalOrderView.vue";

export default {
  components: {
    NbModal,
    NbPageTitle,
    NbButton,
    NbCard,
    NbTextField,
    NbTablev2,
    ModalOrderView,
  },
  name: "ModalVolumeReverseView",
  props: {
    currentVolumeReverse: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      trackingBaseUrl: process.env.VUE_APP_TRACKING_BASE_URL,
      fields: [],
      currentOrderViewId: "",
    };
  },
  computed: {
    allFields() {
      return [
        { key: "name", label: this.$t("name") },
        { key: "sku", label: this.$t("sku") },
        { key: "hs_code", label: this.$t("hsCode") },
        { key: "value", label: this.$t("value") },
        { key: "quantity", label: this.$t("quantity") },
        { key: "description", label: this.$t("description") },
      ];
    },
  },
  methods: {
    getOrderModal(id) {
      if (id) {
        this.currentOrderViewId = id;
        this.$helpers.openModal("order-view-from-volume");
        return;
      }
    },
    reloadOrders() {
      this.$refs?.tableOrders?.getData();
    },
    trackSearch(traking_number) {
      if (traking_number) {
        const lastCharOfTrackingBaseUrl = this.trackingBaseUrl.slice(-1);
        //check the last trackingBaseUrl is a "/" and remove it to avoid router bugs like (...)/trackings/tracking making it like (...)/trackings?trackingNumber=tracking_number
        if (lastCharOfTrackingBaseUrl === "/") {
          this.trackingBaseUrl = this.trackingBaseUrl.slice(0, -1);
        }

        window.open(this.trackingBaseUrl + "?trackingNumber=" + traking_number);
        return;
      }
      location.replace(this.trackingBaseUrl + "404");
    },
    simplifyCurrency(currency) {
      if (currency === "USD" || !currency) {
        return "$";
      } else {
        return currency;
      }
    },
    standardizeValue(val, currency) {
      if (typeof val === "number" || typeof val === "string") {
        return this.simplifyCurrency(currency) + parseFloat(val).toFixed(2);
      }
      return "-";
    },
    standardizeFloat(val, parseVal) {
      if (val) {
        return parseFloat(val).toFixed(parseVal);
      }
      return "-";
    },
    getStatusType(status) {
      let finalType = "warning";

      if (
        status == "solved_issue" ||
        status == "payment_done" ||
        status == "paid_external"
      ) {
        finalType = "success";

        return finalType;
      }

      if (status == "need_seller" || status == "priority_issue") {
        finalType = "danger";

        return finalType;
      }

      return finalType;
    },
    getStatusText(status) {
      let finalText = "";

      if (status == "new_issue") {
        finalText = this.$t("dutiesAndTaxesPage.status.new");
        return finalText;
      }
      if (status == "solved_issue") {
        finalText = this.$t("dutiesAndTaxesPage.status.solved");
        return finalText;
      }
      if (status == "in_progress_issue") {
        finalText = this.$t("dutiesAndTaxesPage.status.inProgress");
        return finalText;
      }
      if (status == "waiting_payment") {
        finalText = this.$t("dutiesAndTaxesPage.status.waitingForPayment");
        return finalText;
      }

      if (status == "priority_issue") {
        finalText = this.$t("dutiesAndTaxesPage.status.priority");
        return finalText;
      }
      if (status == "need_seller") {
        finalText = this.$t("dutiesAndTaxesPage.status.needSelllerAction");
        return finalText;
      }

      finalText = this.transformString(status);
      return finalText;
    },
    transformString(str) {
      let words = str.split("_");

      for (let i = 0; i < words.length; i++) {
        words[i] =
          words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
      }
      return words.join(" ");
    },
  },
};
</script>

<style scoped></style>
