<template>
  <!-- not used -->
  <LoadingPage v-if="loaded" />
  <div v-else>
    <back-button />
    <h5 class="font-weight-light mb-4">
      Reverse Number:
      <span class="font-weight-bold">{{ volumeReverse.reverse_number }}</span>
    </h5>

    <div class="card-deck">
      <!-- volume reverse -->
      <div class="card bg-light border-white rounded">
        <div class="pb-2 pt-4 px-3">
          <h5 class="pl-3">Volume Reverse</h5>
          <hr class="mb-0" />
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Reverse Number:</strong>
          </div>
          <div class="col align-middle">{{ volumeReverse.reverse_number }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Name:</strong>
          </div>
          <div class="col">{{ volumeReverse.name }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Address:</strong>
          </div>
          <div class="col">{{ volumeReverse.address }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Address Number:</strong>
          </div>
          <div class="col">{{ volumeReverse.address_number }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Address Complement:</strong>
          </div>
          <div class="col">{{ volumeReverse.address_complement }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>State:</strong>
          </div>
          <div class="col">{{ volumeReverse.state }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Postal Code:</strong>
          </div>
          <div class="col">{{ volumeReverse.postal_code }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Country:</strong>
          </div>
          <div class="col">{{ volumeReverse.country }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>City:</strong>
          </div>
          <div class="col">{{ volumeReverse.city }}</div>
        </div>
      </div>

      <!-- order -->
      <div class="card bg-light border-white rounded">
        <div class="pb-2 pt-4 px-3">
          <h5 class="pl-3">Order</h5>
          <hr class="mb-0" />
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Order Number:</strong>
          </div>
          <div class="col align-middle">
            <a :href="'/orders/' + volumeReverse.volume.order.id"
              >{{ volumeReverse.volume.order.order_number }}
            </a>
          </div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Sales Channel:</strong>
          </div>
          <div class="col">
            {{ volumeReverse.volume.order.sales_channel_name }}
          </div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Sales Channel Order Number:</strong>
          </div>
          <div class="col">
            {{ volumeReverse.volume.order.sales_channel_order_number }}
          </div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Tracking Number:</strong>
          </div>
          <div class="col">{{ volumeReverse.volume.order.reference }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Total Value:</strong>
          </div>
          <div class="col">
            {{
              volumeReverse.volume.order.currency +
              " " +
              standardizeValue(volumeReverse.volume.order.total_value)
            }}
          </div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Volume ID:</strong>
          </div>
          <div class="col align-middle">{{ volumeReverse.volume.id }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Height:</strong>
          </div>
          <div class="col align-middle">{{ volumeReverse.volume.height }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Width:</strong>
          </div>
          <div class="col align-middle">{{ volumeReverse.volume.width }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Length:</strong>
          </div>
          <div class="col align-middle">{{ volumeReverse.volume.length }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Weight:</strong>
          </div>
          <div class="col align-middle">{{ volumeReverse.volume.weight }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Last mile tracking number:</strong>
          </div>
          <div class="col align-middle">
            {{ volumeReverse.volume.last_mile_tracking_number }}
          </div>
        </div>
      </div>
      <!-- end order -->

      <!-- customer -->
      <div class="card bg-light border-white rounded">
        <div class="pb-2 pt-4 px-3">
          <h5 class="pl-3">Customer</h5>
          <hr class="mb-0" />
        </div>
        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Name:</strong>
          </div>
          <div class="col">
            {{ volumeReverse.volume.order.customer_full_name }}
          </div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>{{
              volumeReverse.volume.order.customer_document_type + ":"
            }}</strong>
          </div>
          <div class="col">
            {{ volumeReverse.volume.order.customer_document_number }}
          </div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Address:</strong>
          </div>
          <div class="col-8">
            {{
              volumeReverse.volume.order.customer_address +
              (volumeReverse.volume.order.customer_address_number
                ? " " + volumeReverse.volume.order.customer_address_number
                : "") +
              (volumeReverse.volume.order.customer_address_complement
                ? ", " + volumeReverse.volume.order.customer_address_complement
                : "") +
              (volumeReverse.volume.order.customer_address_reference
                ? " (" +
                  volumeReverse.volume.order.customer_address_reference +
                  ") "
                : "") +
              " - " +
              volumeReverse.volume.order.customer_city
            }}
          </div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Postal Code:</strong>
          </div>
          <div class="col">
            {{ volumeReverse.volume.order.customer_postal_code }}
          </div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>State:</strong>
          </div>
          <div class="col">{{ volumeReverse.volume.order.customer_state }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Country:</strong>
          </div>
          <div class="col">
            {{ volumeReverse.volume.order.customer_country }}
          </div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Email:</strong>
          </div>
          <div class="col">{{ volumeReverse.volume.order.customer_email }}</div>
        </div>

        <div class="row border rounded bg-white p-2 mx-3 mb-2">
          <div class="col">
            <strong>Phone:</strong>
          </div>
          <div class="col">{{ volumeReverse.volume.order.customer_phone }}</div>
        </div>
      </div>
      <!-- end customer -->
    </div>

    <div class="accordion my-4" id="accordionShowVolumeReverse">
      <div class="card">
        <a
          class="card-header accordion-toggle-white collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#collapseShowVolumeReverseList"
          aria-expanded="false"
          aria-controls="collapseShowVolumeReverseList"
          id="headingShowVolumeReverseList"
        >
          <h5 class="mb-0 mr-5">
            Orders Items:
            <strong>{{ volumeReverse.volume.order_items.length }}</strong>
            <i class="fa fa-chevron-down pull-right"></i>
          </h5>
        </a>
        <div
          id="collapseShowVolumeReverseList"
          class="collapse"
          aria-labelledby="headingShowVolumeReverseList"
          data-parent="#accordionShowVolumeReverse"
        >
          <div>
            <h5 class="mt-4 mb-4 ml-5">Items</h5>
            <table class="table table-borderless rounded">
              <thead>
                <tr>
                  <th class="text-center">Name</th>
                  <th class="text-center">SKU</th>
                  <th class="text-center">HS Code</th>
                  <th class="text-center">Value</th>
                  <th class="text-center">Quantity</th>
                  <th class="text-center">Description</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index_item) in volumeReverse.volume.order_items"
                  :key="index_item"
                >
                  <td class="text-center align-middle">{{ item.name }}</td>
                  <td class="text-center align-middle">{{ item.sku }}</td>
                  <td class="text-center align-middle">{{ item.hs_code }}</td>
                  <td class="text-center align-middle">
                    {{ item.currency || "$" }}{{ standardizeValue(item.value) }}
                  </td>
                  <td class="text-center align-middle">{{ item.quantity }}</td>
                  <td width="30%" class="text-center align-middle">
                    {{ item.description }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "../../components/generic/BackButton";
import VolumeReverseService from "../../services/VolumeReverseService";
import LoadingPage from "@/components/loadings/LoadingPage.vue";

const volumeReverseService = new VolumeReverseService();

export default {
  name: "VolumeReverseView",

  components: { BackButton, LoadingPage },
  data: () => {
    return {
      loaded: true,
      volumeReverse: {},
    };
  },
  beforeMount() {
    volumeReverseService
      .getVolumeReverse(this.$route.params.id)
      .then((response) => {
        const volumeReverse = response.data.data;
        this.volumeReverse = volumeReverse;
        this.loaded = false;
      });
  },
  methods: {
    standardizeValue(val) {
      return parseFloat(val).toFixed(2);
    },
  },
};
</script>
